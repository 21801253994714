
export default {
	name: "CraDepartment",
	data() {
		return {
			httpHeader: {
				token: this.getCookie("token")
			},
			uploadFile: '',
			gUserId: "",
			tableList: [],
			departmentName: '',
			departmentId: '',
			searchMechanism: '',
			fileList: [],
			centerDialogVisible: false,
			isAddMechanism: false,
			aa: '张三-13812345678',
			aaa: '张三四-13812345678',
			addDepartmentName: '',
			uplaodExcelUrl: '',
			departmentInfo: {
				department: {
					name: '',
					hospitalId: '',
					id: ''
				},
				researcherList: []
			},
			isComplete: true,
			changPhone: '',
			isAdd: '',
			mechanismData: '',
			indexNuber: '',
			isPhoneRepeat: false,
			isPhoneLength: false
		};
	},

	created() {
		this.token = this.getCookie("token");
		this.gUserId = this.$route.params.id;
		this.uplaodExcelUrl = this.craUrl + "/uploadFile"
		if (this.token && this.gUserId) {
			this.getDepartmentList();
		}
	},

	methods: {
		getDepartmentList() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/selectdeDartmentListByGuserType", {
					hospitalId: _this.gUserId
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.tableList = res.data.data.selectPage;
						_this.departmentName = res.data.data.hospital.name
						_this.departmentId = res.data.data.hospital.id
					} else if (res.data.code == 401) {
						_this.$router.push("/");
					}
				});
		},
		// 新增机构
		importMechanism() {
			this.centerDialogVisible = true;
		},

		handleExceed(files, fileList) {
			this.$message.warning(`只能上传 1 个文件`);
		},
		// 新增科室
		addMechanism(index) {
			this.indexNuber = index
			this.isAddMechanism = true
			var _this = this
			if (_this.tableList[index]) {
				_this.isAdd = false
				_this.$http.post(_this.craUrl + '/editDepentAndResearcher', {
					dartmentId: _this.tableList[index].id
				}, {
					headers: {
						"token": _this.token
					}
				}).then(function (res) {
					if (res.data.code == 200) {
						_this.departmentInfo = res.data.data
					}
				})
			} else {
				_this.isAdd = true
				_this.departmentInfo = {
					department: {
						name: '',
						hospitalId: _this.departmentId,
						id: ''
					},
					researcherList: []
				}
			}
			setTimeout(function () {
				var oInput = document.getElementById("xzks");
				oInput.focus()
			}, 200)
		},
		// 删除
		deleteDepartment(index) {
			var _this = this;
			if (!_this.tableList[index].researcherNames) {
				this.$confirm("此操作将永久删除该机构, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						_this.$http.post(_this.craUrl + '/delDepartmentById', {
							departmentId: _this.tableList[index].id
						}, {
							headers: {
								"token": _this.token
							}
						}).then(function (res) {
							if (res.data.code == 200) {
								_this.getDepartmentList();
								_this.$message({
									type: "success",
									message: "删除成功!"
								});
							}
						})

					})
					.catch(() => {

					});
			} else {
				_this.$message({
					type: "warning",
					message: "不可删除!"
				});
			}

		},
		// 删除研究者
		delResearcher(item, index) {
			var _this = this
			if (item.id) {
				_this.$http
					.post(
						_this.craUrl + "/isDelResearcher", {
						researcherId: item.id
					}, {
						headers: {
							token: _this.token
						}
					}
					)
					.then(function (res) {
						if (res.data.code == 200) {
							if (res.data.data) {
								_this.departmentInfo.researcherList[index].isdel = 1

							} else {
								_this.$message({
									type: "warning",
									message: "该研究者不可删除!"
								});
							}

						} else if (res.data.code == 401) {
							_this.$router.push("/");
						} else {

						}
					});
			} else {
				_this.departmentInfo.researcherList[index].isdel = 1
			}


		},
		// 添加研究者
		addResearcher() {
			var arr = this.departmentInfo.researcherList
			var obj = {
				createId: '',
				createTime: '',
				departmentId: arr[0] ? arr[0].departmentId : '',
				departmentName: arr[0] ? arr[0].departmentName : '',
				hospitalId: arr[0] ? arr[0].hospitalId : '',
				hospitalName: arr[0] ? arr[0].hospitalName : '',
				id: '',
				isdel: '',
				name: '',
				openId: '',
				phone: '',
				projectList: '',
				standby1: '',
				standby2: '',
				unionId: '',
				updateTime: '',
				yn: '',
			}
			this.departmentInfo.researcherList.push(obj)
		},
		// 保存
		saveDepartmentInfo() {
			var _this = this
			var arr = _this.departmentInfo.researcherList
			_this.isPhoneRepeat = false
			_this.isPhoneLength = false
			var ary = []
			for (let i = 0; i < arr.length; i++) {
				ary.push(arr[i].phone)
				if (arr[i].isdel != '1') {
					if (!arr[i].name || !arr[i].phone || arr[i].phone.length != 11) {
						arr[i].standby2 = 'true'
					}
				}
			}

			for (let i = 0; i < arr.length; i++) {
				if (arr[i].isdel != '1') {
					if (arr[i].standby2 == 'true') {
						_this.isPhoneRepeat = true
						_this.isPhoneLength = true
						break
					} else {
						_this.isPhoneRepeat = false
						_this.isPhoneLength = false
					}
				}
			}


			if (!_this.departmentInfo.department.name) {
				_this.$message({
					message: "请填写科室名称!",
					type: "warning"
				});
			} else if (!_this.isComplete) {
				_this.$message({
					message: "请填写完整的研究者信息!",
					type: "warning"
				});
			} else if (_this.isPhoneRepeat || _this.isPhoneLength) {
				_this.$message({
					message: "标红研究者手机号已存在或格式不正确，请修改!",
					type: "warning"
				});
			}
			else {
				_this.$http.post(_this.craUrl + '/saveOrUpdateDepentAndResearcher', {
					department: _this.departmentInfo.department,
					researcherList: _this.departmentInfo.researcherList
				}, {
					headers: {
						"token": _this.token
					}
				}).then(function (res) {
					if (res.data.code == 200) {
						_this.getDepartmentList();
						_this.isAddMechanism = false
					}
				})
			}

		},
		handleAvatarSuccess(res, file) {
			this.uploadFile = res.data.name;
		},
		// 删除上传文件
		handleRemove(file, fileList) {
			this.uploadFile = ''
		},
		// 保存导入的内容
		saveUplaodExcelUrl() {
			var _this = this
			_this.$http
				.post(
					_this.craUrl + "/uplaodExcelResearcher", {
					histipalId: _this.departmentId,
					fileUrl: _this.uploadFile
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.getDepartmentList()
						_this.centerDialogVisible = false
					}
				});
		},
		// 获取手机号
		getPhone(index) {
			var _this = this
			if (_this.indexNuber || _this.indexNuber == 0) {
				_this.$http.post(_this.craUrl + '/editDepentAndResearcher', {
					dartmentId: _this.tableList[_this.indexNuber].id
				}, {
					headers: {
						"token": _this.token
					}
				}).then(function (res) {
					if (res.data.code == 200) {
						if (res.data.data.researcherList[index]) {
							_this.changPhone = res.data.data.researcherList[index].phone
						} else {
							_this.changPhone = ''
						}
					}
				})
			}

		},
		verificationPhone(e, index) {
			var _this = this
			var arr = _this.departmentInfo.researcherList
			// for(var i=0;i<arr.length;i++){
			// 	if(arr[i].isdel!=1){
			// 		if(e==arr[i].phone){
			// 			arr[arr.length-1].standby2 = 'true'
			// 			_this.isPhonseRepeat = true
			// 			_this.$message({
			// 				type: "warning",
			// 				message: "aaaa!"
			// 			});
			// 		}
			// 	}

			// }

			if (_this.isAdd) {

			} else {
				if (e == _this.changPhone && e.length == 11) {
					return
				}
			}
			_this.$http.post(_this.craUrl + '/phoneIsRepeat', {
				phone: e
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (res) {
				if (res.data.code == 200) {
					if (res.data.data && e.length == 11) {
						_this.$message({
							type: "warning",
							message: "研究者手机号已存在，请重新填写!"
						});
						_this.isPhoneRepeat = true
						_this.departmentInfo.researcherList[index].standby2 = 'true'
					} else if (e.length != 11) {
						_this.isPhoneLength = true
						_this.departmentInfo.researcherList[index].standby2 = 'true'
						_this.$message({
							type: "warning",
							message: "研究者手机号格式不正确，请重新填写!"
						});
					}
					else {
						// _this.isPhoneRepeat = false
						_this.isPhoneLength = false
						_this.departmentInfo.researcherList[index].standby2 = 'false'
					}
				}
			})
		}

	},


};
