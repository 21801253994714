import { render, staticRenderFns } from "./CraDepartment.vue?vue&type=template&id=6cba8bca&scoped=true&"
import script from "./CraDepartment.js?vue&type=script&lang=js&"
export * from "./CraDepartment.js?vue&type=script&lang=js&"
import style0 from "./CraDepartment.less?vue&type=style&index=0&id=6cba8bca&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cba8bca",
  null
  
)

export default component.exports